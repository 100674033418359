.ClientLogo {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 50px;
    opacity: 0.8;
    animation: fadeIn 2s infinite alternate;
    filter: grayscale(100%);
  }