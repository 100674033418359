.Avatar {
  margin: 0px 0 10px 10px;
  cursor: pointer;
}

.Configuration-logo {
  height: 30px;
  margin: 5px 30px 5px 15px;
  cursor: pointer;
}

.Configuration-fluidX {
  height: 30px;
  margin: 20px 0 10px 15px;
}

.Configuration-all {
  overflow-y: hidden;
  overflow-x: hidden;
}

.Configuration-header {
  background-color: #0054aa;
  width: 100%;
  height: 46px;
  margin: 0;
  align-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: calc(10px + 2vmin);
  color: #fff;
  overflow-y: hidden;
  overflow-x: hidden;
}

.Configuration-header-left {
  width: 100%;
  margin: 0;
  align-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  overflow-y: hidden;
  overflow-x: hidden;
}

.Configuration-header-right {
  width: 400px;
  margin: 0;
  align-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  overflow-y: hidden;
  overflow-x: hidden;
}

.Configuration-tab {
  font-size: 18px !important;
}

.Button-Home {
  color: white !important;
  cursor: pointer;
  margin-top: 8px;
}

.Username {
  margin: 0 40px 0 20px;
  font-size: 16px;
}

.Configuration-content {
  margin: 0;
  overflow-y: hidden;
  overflow-x: hidden;
}

.Tab-content-padding {
  padding: 0px !important;
}

@keyframes show {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

.SlowShow {
  animation: show 500ms linear;
  animation-fill-mode: forwards;
}
